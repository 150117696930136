// App.tsx
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { SignedIn, SignedOut, RedirectToSignIn } from '@clerk/clerk-react';
import { LIGHT_THEME, DARK_THEME } from './theme';

import Home from './pages/Home';
import NilResources from './pages/NilResources';
import Blog from './pages/Blog';
import Login from './pages/Login';
import HowItWorks from './pages/HowItWorks';
import ConnectWithAPro from './pages/ConnectWithAPro';
import Account from './pages/Account';
import SignUp from './pages/SignUp';

// Remove the environment variable access and ClerkProvider here

const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const theme = isDarkMode ? DARK_THEME : LIGHT_THEME;

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/connect" element={<ConnectWithAPro />} />
            <Route path="/how" element={<HowItWorks />} />
            <Route path="/nil" element={<NilResources />} />
            <Route path="/blog" element={<Blog />} />
            {/* Protected route */}
            <Route
              path="/account"
              element={
                <>
                  <SignedIn>
                    <Account />
                  </SignedIn>
                  <SignedOut>
                    <RedirectToSignIn redirectUrl="/login" />
                  </SignedOut>
                </>
              }
            />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
