import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import backgroundImage from '../assets/OlympicGold.webp';
import Navbar from '../components/Navbar';
import LogoContainerImage from '../assets/Couro.png';
import { FcGoogle } from 'react-icons/fc';
import Footer from '../components/Footer';
import { useSignUp } from '@clerk/clerk-react';

// Styled Components
// ... [existing styled components] ...
const ErrorList = styled.ul`
  color: red;
  list-style-type: none;
  padding: 0;
`;

// Styled Components
const LoginContainer = styled.div`
  height: 690px; /* Adjusted height to accommodate new content */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-top: 1.8rem;
  z-index: 110;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
`;

const LoginInfoContainer = styled.div`
  height: 555px; /* Adjusted height */
  margin-top: 92px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  color: white;
  max-width: 380px;
  width: 45vw;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  @media (max-width: 768px) {
    width: 55vw;
  }
`;

const LogoContainer = styled.div`
  height: 100px;
  width: 120px;
  background-image: url(${LogoContainerImage});
  background-size: cover;
  background-position: center;
`;

const Heading = styled.p`
  font-weight: 600;
  font-size: 1.3rem;
`;

const SubHeading = styled.p`
  font-weight: 600;
  font-size: 0.8rem;
  color: rgb(150, 150, 150);
`;

const Input = styled.input`
  width: 71%;
  padding: 9px 8px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: rgba(225, 225, 225, 0.12);
  border: 2px solid transparent;
  color: white;

  &::placeholder {
    color: rgb(200, 200, 200); /* Light gray placeholder text */
    margin-top: 1px;
  }

  &:hover {
    border-color: gold; /* Light gold border on hover */
  }

  &:focus {
    outline: none;
    border-color: gold; /* Keep border when focused */
  }
`;


const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: center; /* Center items horizontally */

  @media (max-width: 768px) {
    flex-direction: row; /* Stack items vertically on mobile */
    text-align: center;     /* Center text */
  }
`;
const SubTos = styled.p`  /* Changed to <p> for better semantics */
  font-size: 0.8rem;
  color: rgb(200, 200, 200);
  margin: 0 5px;           /* Adjust margins */

  @media (max-width: 768px) {
  font-size: 0.6rem;
    margin-right: 0.3rem;             /* Reset margins on mobile */
  }
`;

const Tos = styled.a`
  font-size: 0.8rem;
  color: rgb(130, 130, 130);
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 0.6rem;
  }
`;
const Checkbox = styled.input`
  margin-left: -35px;

  @media (max-width: 768px) {
    margin-left: 0;        /* Reset margin on mobile */
    margin-bottom: 0.5rem; /* Add space below the checkbox */
  }
`;

const ContinueButton = styled.button`
  width: 75%;
  padding: 7px 4px;
  margin-top: 15px;
  background-color: gold;
  font-size: 18px;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

const Separator = styled.div`
  width: 68%;
  height: 1px;
  background-color: rgba(150, 150, 150, 0.4);
  margin: 14px 0;
`;


const GoogleButton = styled.button`
  width: 74%;
  padding: 4px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px; /* Space between icon and text */
`;


const SignUpLink = styled(Link)`
  font-size: 0.8rem;
  color: rgb(150, 150, 150); 
  cursor: pointer;
  margin-top: 2rem;
  text-decoration: none;
`;



export default function SignUp() {
  const [agreed, setAgreed] = useState(false);
  const [email, setEmail] = useState('');
  const [step, setStep] = useState<'email' | 'code'>('email');
  const [code, setCode] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const navigate = useNavigate();
  const { isLoaded, signUp } = useSignUp();

  const handleCheckboxChange = () => {
    setAgreed(!agreed);
  };

  const handleContinue = async () => {
    if (!isLoaded) {
      return;
    }
    if (!agreed) {
      setErrors(['You must agree to the terms of service.']);
      return;
    }
    try {
      await signUp.create({
        emailAddress: email,
      });
      await signUp.prepareEmailAddressVerification();
      setStep('code');
      setErrors([]);
    } catch (err: any) {
      const errorMessages = err.errors ? err.errors.map((e: any) => e.message) : ['An error occurred'];
      setErrors(errorMessages);
    }
  };
  const handleVerifyCode = async () => {
    if (!isLoaded || !signUp) {
      return;
    }
    try {
      await signUp.attemptEmailAddressVerification({
        code,
      });
      if (signUp.status === 'complete') {
        navigate('/account');
      } else {
        // Handle other statuses if necessary
        console.log('Sign-up status:', signUp.status);
      }
    } catch (err: any) {
      const errorMessages = err.errors ? err.errors.map((e: any) => e.message) : ['Invalid code'];
      setErrors(errorMessages);
    }
  };

  const handleGoogleSignIn = () => {
    if (!isLoaded) {
      return;
    }
    signUp.authenticateWithRedirect({
      strategy: 'oauth_google',
      redirectUrl: '/account',          // After authentication starts
      redirectUrlComplete: '/account',  // After authentication completes
    });
  };

  return (
    <>
      <LoginContainer>
        <Navbar />
        <LoginInfoContainer>
          <LogoContainer />
          {step === 'email' ? (
            <>
              <Heading>Sign Up to Couro</Heading>
              <SubHeading>Please enter your email address</SubHeading>

              {/* Input Form */}
              <Input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              {/* Terms of Service Container */}
              <TermsContainer>
                <Checkbox
                  type="checkbox"
                  checked={agreed}
                  onChange={handleCheckboxChange}
                />
                <SubTos>I agree to the</SubTos>
                <Tos
                  href="https://docs.google.com/document/u/0/d/e/2PACX-1vQtTrsvyWtJwMh4irpjkCqLOzMTFr-Dbbprh9ckuS7q8m5wskpF0P453SDPhzcEuXOR_BR7M_K13Y2y/pub?pli=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of services
                </Tos>
              </TermsContainer>

              {/* Error Messages */}
              {errors.length > 0 && (
                <ErrorList>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ErrorList>
              )}

              {/* Continue Button */}
              <ContinueButton disabled={!agreed} onClick={handleContinue}>
               Continue
              </ContinueButton>


              {/* Separator */}
              <Separator />

              {/* Google Button */}
              <GoogleButton onClick={handleGoogleSignIn}>
                <FcGoogle style={{ marginRight: '10px' }} size={30} /> Continue with Google
              </GoogleButton>
              <SignUpLink to="/login">Back to Login Page</SignUpLink>
            </>
          ) : (
            <>
              <Heading>Enter Verification Code</Heading>
              <SubHeading>A verification code was sent to {email}</SubHeading>

              {/* Code Input */}
              <Input
                type="text"
                placeholder="Enter Verification Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />

              {/* Error Messages */}
              {errors.length > 0 && (
                <ErrorList>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ErrorList>
              )}

              {/* Sign Up Button */}
              <ContinueButton onClick={handleVerifyCode}>
                Sign Up
              </ContinueButton>

              {/* Option to Resend Code */}
              <SubHeading>
                Didn't receive a code?{' '}
                <Tos
                  as="span"
                    onClick={async () => {
                   if (!isLoaded || !signUp) {
                return;
                    }
                  await signUp.prepareEmailAddressVerification();
                    alert('Verification code resent');
                    }}
                style={{ cursor: 'pointer' }}
                >
                Resend Code
                </Tos>
              </SubHeading>
            </>
          )}
        </LoginInfoContainer>
      </LoginContainer>
      <Footer />
    </>
  );
}