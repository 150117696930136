import React from 'react';
import styled from 'styled-components';

// Styled Components
const PostContainer = styled.div`
  width: 85%; /* Set post width to 80% of ScrollableFeed */
  min-height: 600px;
  background-color: #f0f0f0;
  border-radius: 16px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column; /* Arrange child elements vertically */
  padding: 1rem;
  border: none;

  @media (max-width: 768px) {
    width: 100%;
    background-color: transparent;
    border: 1px solid rgb(225, 225, 225, 0.5); /* Border on mobile */
    border-radius: 0; /* Remove border radius on mobile if desired */
  }
`;

const PostTitle = styled.div`
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgb(200, 200, 200);
  margin: 1rem 0;
`;

const ContentArea = styled.div`
  background-color: rgb(100, 100, 100);
  width: 100%;
  height: 80%; /* Adjust as needed */
  border-radius: 8px;
`;

const UserPost = styled.div`
  margin-top: 1rem;
  text-align: left;
  font-size: 1rem;
  padding-bottom: 3rem;
`;

const Post: React.FC = () => {
  return (
    <PostContainer>
      <PostTitle>Post</PostTitle>
      <Separator />
      <ContentArea />
      <UserPost>User Post</UserPost>
    </PostContainer>
  );
};

export default Post;
