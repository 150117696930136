// Footer.tsx
import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import FooterLogo from './FooterLogo';

// Lazy load individual icons
const FaInstagram = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaInstagram }))
);
const FaSpotify = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaSpotify }))
);
const FaYoutube = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaYoutube }))
);
const FaTwitter = lazy(() =>
  import('react-icons/fa').then((module) => ({ default: module.FaTwitter }))
);

// Styled Components
const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme.primaryBackground};
  color: ${(props) => props.theme.textColor};
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column; /* Stack items vertically on mobile */
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row; /* Align items horizontally on tablet and above */
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;
  }
`;

const LogoCopyrightContainer = styled.div`
  display: flex;
  flex-direction: row; /* Stack logo and text vertically on mobile */
  align-items: center;
  margin-bottom: 1rem; 

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column; /* Align logo and text horizontally */
    margin-bottom: 0;
  }
`;

const CopyrightText = styled.div`
  font-size: 1rem;
  margin-top: 0rem;
  margin-left. 2rem;
  color: ${(props) => props.theme.navLinkColor};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 0;
    margin-left: 1rem;
    font-size: 1rem;
  }
`;

const LinkandSocialsContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack links and socials vertically on mobile */
  align-items: center;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row; /* Align links and socials horizontally */
    justify-content: space-between;
    align-items: center;
    width: auto;
  }
`;

const LinksContainer = styled.nav`
  display: flex;
  flex-direction: column; /* Stack links vertically on mobile */
  align-items: center;
  font-size: 1rem;
  margin-bottom: 1rem; /* Add spacing below links */

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row; /* Align links horizontally */
    margin: 0;
    margin-right: 1.9rem;
    font-size: 1rem;
  }
`;

const FooterLink = styled(Link)`
  color: ${(props) => props.theme.navLinkColor};
  text-decoration: none;
  margin: 0.5rem 0; /* Add vertical spacing between links */

  &:hover {
    color: ${(props) => props.theme.hoverGray};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 1rem; /* Add horizontal spacing on tablet and above */
  }
`;

const IconContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    justify-content: flex-end;
  }
`;

const SocialLink = styled.a`
  color: ${(props) => props.theme.navLinkColor};
  font-size: 1.5rem; /* 24px */
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.hoverGray};
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      {/* Logo and Copyright */}
      <Suspense fallback={<div>Loading...</div>}>
        <LogoCopyrightContainer>
          <FooterLogo />
          <CopyrightText>© Couro 2024</CopyrightText>
        </LogoCopyrightContainer>
      </Suspense>

      <LinkandSocialsContainer>
        {/* Links Section */}
        <LinksContainer aria-label="Footer Navigation">
          <FooterLink to="/about">About Us</FooterLink>
          <FooterLink to="/connect">Connect With a Pro</FooterLink>
          <FooterLink to="/nil">NIL Resources</FooterLink>
          <FooterLink to="/blog">Blog</FooterLink>
          <FooterLink to="/how">How It Works</FooterLink>
        </LinksContainer>

        {/* Social Media Links */}
        <IconContainer>
          <Suspense fallback={<div>Loading...</div>}>
            <SocialLink
              href="https://www.instagram.com/couroforathletes/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram />
            </SocialLink>
            <SocialLink
              href="https://www.spotify.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Spotify"
            >
              <FaSpotify />
            </SocialLink>
            <SocialLink
              href="https://www.youtube.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
            >
              <FaYoutube />
            </SocialLink>
            <SocialLink
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <FaTwitter />
            </SocialLink>
          </Suspense>
        </IconContainer>
      </LinkandSocialsContainer>
    </FooterContainer>
  );
};

export default Footer;
