// theme.ts

export const COLORS = {
    gold: '#FFD700',
    hoverGray: '#f0f0f0',
  };
  
  export const BREAKPOINTS = {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
  };
  
  export const LIGHT_THEME = {
    primaryBackground: '#000000',
    textColor: '#000000',
    navLinkColor: COLORS.gold,
    hoverGray: COLORS.hoverGray,
    breakpoints: BREAKPOINTS,
  };
  
  export const DARK_THEME = {
    primaryBackground: '#000000',
    textColor: '#FFFFFF',
    navLinkColor: COLORS.gold,
    hoverGray: COLORS.hoverGray,
    breakpoints: BREAKPOINTS,
  };
  