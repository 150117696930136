// pages/Home.tsx
import React from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HeroSection from '../components/HomePage/HeroSection';
import WhyAnalyzeSection from '../components/HomePage/WhyAnalyzeSection';

const HomeContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
`;

const Home: React.FC = () => {
  return (
    <>
      <HomeContainer>
        <Navbar />
        <HeroSection />
        <WhyAnalyzeSection />
        <h1>Welcome to the Home Page</h1>
      </HomeContainer>
      <Footer />
    </>
  );
};

export default Home;
