import React, { useState } from 'react';
import styled from 'styled-components';
import AccountNavbar from '../components/AccountNavbar';
import Post from '../components/Feed/Post'; // Adjust the import path as needed
import { useUser } from '@clerk/clerk-react';

const AccountContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const ScrollableFeed = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 2rem;
`;

const AdContainer = styled.div`
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  /* Hide on mobile devices */
  @media (max-width: 768px) {
    display: none;
  }
`;

const PromoBox = styled.div`
  width: 90%;
  height: 100px;
  border: 2px solid gold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PromoText = styled.p`
  text-align: center;
  color: white;
`;

const GrayBox = styled.div`
  width: 90%;
  height: 600px;
  background-color: gray;
  border-radius: 10px;
  margin-top: 1rem;
`;

/* Button Container */
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
`;

/* Tab Button Styles */
const TabButton = styled.button`
  flex: 1;
  background-color: transparent;
  border: none;
  color: white;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  position: relative;
  transition: color 0.3s;

  &:hover {
    color: gold;
  }
`;

const Account: React.FC = () => {
  const { isLoaded, isSignedIn, user } = useUser();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  if (!isLoaded) {
    // User data is still loading
    return <div>Loading...</div>;
  }

  if (!isSignedIn || !user) {
    // User is not signed in
    return <div>Please sign in to access this page.</div>;
  }

  return (
    <AccountContainer>
      <AccountNavbar isExpanded={isExpanded} toggleNavbar={toggleNavbar} />
      <ScrollableFeed>
        {/* Button Container */}
        <ButtonContainer>
          <TabButton>Explore</TabButton>
          <TabButton>Following</TabButton>
        </ButtonContainer>

        <h1>Account</h1>
        <h1>Welcome, {user.firstName}!</h1>
        <Post />
        <Post />
        <Post />
        <Post />
      </ScrollableFeed>

      {/* Ad Container */}
      <AdContainer>
        <PromoBox>
          <PromoText>Want to be a pro?</PromoText>
        </PromoBox>
        <GrayBox />
      </AdContainer>
    </AccountContainer>
  );
};

export default Account;
