import React, { useState } from 'react';
import styled from 'styled-components';
import { FaUser } from 'react-icons/fa';
import LoggedInLogo from './LoggedInLogo';
import { useNavigate } from 'react-router-dom';
import { useClerk } from '@clerk/clerk-react';

interface AccountNavbarProps {
  isExpanded: boolean;
  toggleNavbar: () => void;
}

const NavbarContainer = styled.nav<{ isExpanded: boolean }>`
  position: relative; /* Part of normal flow */
  height: 100vh;
  width: ${({ isExpanded }) => (isExpanded ? '225px' : '50px')};
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border-right: 1px solid rgba(225, 225, 225, 0.6);
  transition: width 0.3s ease;
`;

const ExpandButton = styled.button`
  display: block;
  position: absolute;
  top: 10px;
  right: -5px;
  background-color: transparent;
  border: 1px solid rgb(195,195,195);
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.8rem;
  z-index: 2000;
`;

const AccountPic = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-bottom: 2rem;
  margin-top: auto; /* Pushes the element to the bottom */
  border-radius: 10px;
  border: 1px solid rgba(205, 205, 205, 0.6);
  &:hover {
    background-color: rgb(180, 180, 180);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  min-width: 300px;
`;

const ModalButton = styled.button<{ isCancel?: boolean }>`
  background-color: ${({ isCancel }) => (isCancel ? '#ccc' : 'red')};
  color: ${({ isCancel }) => (isCancel ? '#000' : '#fff')};
  border: none;
  padding: 0.8rem 1.2rem;
  margin: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
`;

const AccountNavbar: React.FC<AccountNavbarProps> = ({ isExpanded, toggleNavbar }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  const { signOut } = useClerk();

  const handleAccountPicClick = () => {
    setIsModalOpen(true);
  };

  const handleLogoutClick = () => {
    setShowConfirmation(true);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
    setShowConfirmation(false);
  };

  const handleConfirmLogout = async () => {
    await signOut();
    navigate('/login');
  };

  const handleCancelLogout = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <NavbarContainer isExpanded={isExpanded}>
        <LoggedInLogo />
        <ExpandButton className="expand-button" onClick={toggleNavbar}>
          {isExpanded ? '<' : '>'}
        </ExpandButton>
        <AccountPic onClick={handleAccountPicClick}>
          <FaUser />
        </AccountPic>
      </NavbarContainer>

      {isModalOpen && (
        <ModalOverlay>
          {showConfirmation ? (
            <ModalContent>
              <p>Are you sure you would like to logout?</p>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ModalButton onClick={handleConfirmLogout}>Yes</ModalButton>
                <ModalButton isCancel onClick={handleCancelLogout}>
                  No
                </ModalButton>
              </div>
            </ModalContent>
          ) : (
            <ModalContent>
              <ModalButton onClick={handleLogoutClick} style={{ backgroundColor: 'red', color: 'white' }}>
                Logout
              </ModalButton>
              <ModalButton isCancel onClick={handleCancelClick}>
                Cancel
              </ModalButton>
            </ModalContent>
          )}
        </ModalOverlay>
      )}
    </>
  );
};

export default AccountNavbar;