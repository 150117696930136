// components/Logo.tsx
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; 
import LogoImageSrc from '../assets/Couro_darkText.png'; 

const LogoContainer = styled.div`
  text-align: center;
`;

const LogoImage = styled.img`
  width: 50px;
  height: 50px;
`;

const LoggedInLogo: React.FC = () => {
  return (
    <LogoContainer>
        <Link to="/"> 
          <LogoImage src={LogoImageSrc} alt="Logo" />
        </Link>
    </LogoContainer>
  );
};

export default LoggedInLogo;
